<!--
 * @Description: 分页
 * @Author: wangying
 * @Date: 2021-09-04 12:49:38
-->
<template>
  <div style="text-align: right;padding: 5px 0;">
    <el-pagination
      :current-page="value"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      :total="count"
      background
      layout="total,sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>

export default {
  name: 'pagination',
  props: {
    value: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return [5,10, 20, 50, 100, 200]
        // return [5, 10, 15, 20]
      }
    },
    count: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSizeChange(size) {
      this.$emit('update:pageSize', size)
      if (this.value !== 1) {
        this.$emit('input', 1)
      }
      this.$emit('loadData')
    },
    handleCurrentChange(curPage) {
      this.$emit('input', curPage)
      this.$emit('loadData')
    }
  }
}
</script>
