<!--
 * @Description: 选择教室信息
 * @Author: liqin
 * @Date: 2021-12-28 13:44:46
-->
<template>
  <div>
    <el-form ref="form" label-width="90px">
      <el-row :gutter="5">
        <el-col :span="8">
          <el-form-item label="学院信息：">
            <el-input v-model="merchantNameOrNum" clearable placeholder="请输入学院名称或编码"
              @change="findMerchantByPage"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="教室名称：">
            <el-input v-model="laboratoryName" clearable placeholder="请输入教室名称" @change="findMerchantByPage"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="教室编码：">
            <el-input v-model="laboratoryNum" clearable placeholder="请输入教室编码" @change="findMerchantByPage"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div class="max_H50vh">
      <el-table :data="tableData" style="width: 100%;">
        <el-table-column prop="" label="选择" width="80">
          <template slot-scope="scope">
            <el-radio v-model="radio" :label="scope.row">{{ '\xa0' }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column width="55" label="序号" align="center">
          <template slot-scope="scope">
            <span>{{ (params.page - 1) * params.limit + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="merchantName" label="学院名称" width="180" align="center" />
        <el-table-column prop="laboratoryNum" label="教室编码" width="180" align="center">
        </el-table-column>
        <el-table-column prop="laboratoryName" label="教室名称" width="180" align="center">
        </el-table-column>
        <el-table-column prop="leadName" label="预约方式" width="180" align="center">
          <template slot-scope="scpoe">
            {{ scpoe.row.appointmentWay == 1 ? '按位置' : '按教室' }}
          </template>
        </el-table-column>
      </el-table>
      <pagination v-model="params.page" :page-size.sync="params.limit" :count="count" @loadData="loadData" />
    </div>
  </div>
</template>
<script>
import pagination from '@/components/pagination'

export default {
  components: { pagination },
  props: {
    value: [Boolean],
    merchantId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      dialogVisible: false,
      radio: {},
      tableData: [],
      params: { limit: 10, page: 1 },
      count: 0,
      merchantNameOrNum: '',
      laboratoryName: '',
      laboratoryNum: ''

    }
  },
  created() {
    // 选择学院
    this.findMerchantByPage()
  },
  methods: {
    // 选择学院
    findMerchantByPage() {
      const params = {
        token: this.token,
        ...this.params,
        // thisstate: 1,
        roomState: 1,
        // isPositionAppointment: 1,
        merchantId: this.merchantId,
        merchantNameOrNum: this.merchantNameOrNum ?? '',
        laboratoryName: this.laboratoryName ?? '',
        laboratoryNum: this.laboratoryNum ?? ''
      }
      this.$api.queryLabortoryList(params).then(res => {
        console.log(res)
        this.tableData = res.data.data || []
        this.count = res.data.count
      })
    },
    handleClose() {
      this.$emit('input', false)
    },
    submit() {
      this.$emit('input', false)
      this.$emit('update:classroomObj', this.radio)
      console.log(this.radio)
    },
    loadData() {
      this.findMerchantByPage()
    }
  }
}
</script>
