<template>
    <d2-container class="module-container">
        <el-card class="mb10" :body-style="{ padding: '10px 20px' }">
            <el-form ref="searchForm" :model="searchForm" :inline="true" @submit.native.prevent>
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="选择教室" prop="">
                            <el-input @focus="classroomDialog = true" v-model="searchForm.laboratoryName"
                                placeholder="请选择"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4" style="text-align:right">
                        <el-button type="" @click="cancelapppintment()">取消</el-button>
                        <el-button type="" @click="yuyue()">预约</el-button>
                    </el-col>
                </el-row>
            </el-form>

        </el-card>
        <el-card class="mb10" :body-style="{ padding: '10px 20px' }">
            <div style="text-align:center;padding:15px;line-height: 22px;font-size: 22px;font-weight: bold;">
                {{ searchForm.laboratoryName || '' }}<span style="padding-left:10px">课程表</span>
            </div>
            <div style="text-align:center;font-size: 14px;line-height: 14px;font-weight: bold;">当前日期：{{ currentDay }}</div>
            <div
                style="display: -webkit-flex;display: flex;align-items:center; align-content:center;justify-content:center;">
                <div><el-button type="primary" size="medium" @click="preWeek">上一周</el-button></div>
                <div style="width:250px"></div>
                <div><el-button type="primary" size="medium" @click="nextWeek">下一周</el-button></div>
            </div>
        </el-card>
        <el-card v-if="weeks && weeks.length > 0" class="mb10" :body-style="{ padding: '10px 20px' }">
            <div class='class-table'>
                <div class='table-wrapper'>
                    <div class='tabel-container'>
                        <table>
                            <thead>
                                <tr>
                                    <th>时间</th>
                                    <th v-for='(week, index) in weeks' :key='index'>
                                        <p>{{ week.week }}</p>
                                        <p class='period'>{{ week.date }}</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="classTableData.length != 0">
                                <tr v-for='(item, index) in classTableData' :key='index'>
                                    <td>
                                        <p>{{ '第' + Arabia_To_SimplifiedChinese(index + 1) + '节' }}</p>
                                        <p class='period'>{{ item.startTime }}-{{ item.endTime }}</p>
                                    </td>

                                    <td @click="cellChange(item, weeks[0].date, item.monday.isHas, item.monday.orderId)"
                                        :style="isguoqi(weeks[0].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.monday.isHas == 1 && !handler(dateArr, { longTime: weeks[0].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[0].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.monday.isHas == 1">
                                            <p class='period'>{{ item.monday.teacherName || '' }}</p>
                                            <p class='period'>{{ item.monday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>
                                    <td @click="cellChange(item, weeks[1].date, item.tuesday.isHas, item.tuesday.orderId)"
                                        :style="isguoqi(weeks[1].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.tuesday.isHas == 1 && !handler(dateArr, { longTime: weeks[1].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[1].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.tuesday.isHas == 1">
                                            <p class='period'>{{ item.tuesday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>
                                    <td @click="cellChange(item, weeks[2].date, item.wednesday.isHas, item.wednesday.orderId)"
                                        :style="isguoqi(weeks[2].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.wednesday.isHas == 1 && !handler(dateArr, { longTime: weeks[2].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[2].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.wednesday.isHas == 1">
                                            <p class='period'>{{ item.wednesday.teacherName || '' }}</p>
                                            <p class='period'>{{ item.wednesday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>
                                    <td @click="cellChange(item, weeks[3].date, item.thursday.isHas, item.thursday.orderId)"
                                        :style="isguoqi(weeks[3].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.thursday.isHas == 1 && !handler(dateArr, { longTime: weeks[3].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[3].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.thursday.isHas == 1">
                                            <p class='period'>{{ item.thursday.teacherName || '' }}</p>
                                            <p class='period'>{{ item.saturday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>
                                    <td @click="cellChange(item, weeks[4].date, item.friday.isHas, item.friday.orderId)"
                                        :style="isguoqi(weeks[4].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.friday.isHas == 1 && !handler(dateArr, { longTime: weeks[4].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[4].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.friday.isHas == 1">
                                            <p class='period'>{{ item.friday.teacherName || '' }}</p>
                                            <p class='period'>{{ item.saturday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>
                                    <td @click="cellChange(item, weeks[5].date, item.saturday.isHas, item.saturday.orderId)"
                                        :style="isguoqi(weeks[5].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.saturday.isHas == 1 && !handler(dateArr, { longTime: weeks[5].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[5].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.saturday.isHas == 1">
                                            <p class='period'>{{ item.saturday.teacherName || '' }}</p>
                                            <p class='period'>{{ item.saturday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>
                                    <td @click="cellChange(item, weeks[6].date, item.sunday.isHas, item.sunday.orderId)"
                                        :style="isguoqi(weeks[6].date + ' ' + item.startTime) ? 'backgroundColor:gray;color:#fff;' : (
                                            item.sunday.isHas == 1 ? 'backgroundColor: rgb(0, 153, 255); color:#fff;' : (
                                                handler(dateArr, { longTime: weeks[6].date + ' ' + item.startTime + '~' + item.endTime }) ? 'backgroundColor: orange; color:#fff;' : 'backgroundColor:rgb(103, 194, 58); color:#fff;'
                                            )
                                        )">
                                        <div v-if="item.sunday.isHas == 1">
                                            <p class='period'>{{ item.sunday.teacherName || '' }}</p>
                                            <p class='period'>{{ item.sunday.studentNo || '' }}</p>
                                        </div>
                                        <span v-else></span>
                                    </td>

                                </tr>
                            </tbody>
                            <tbody v-else>
                                <td colspan="8">
                                    <el-empty :image-size="200"></el-empty>
                                </td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </el-card>
        <!-- 选择教室 -->
        <el-dialog title="选择教室" v-if="isRouterAlive" :visible.sync="classroomDialog" width="60%"
            :close-on-click-modal="false" v-dialogDrag>
            <div class="max_H50vh">
                <classroom ref="classroomRef"></classroom>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="classroomDialog = false">取 消</el-button>
                <el-button type="primary" @click="classroomAffirm">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-dialogDrag class="el-dialog1" title="教室预约" :visible.sync="dialogVisible" width="50%"
            :before-close="handleClose">

            <div class="content max_H50vh">
                <div class="time">
                    <div class="title">
                        <div class="text">{{ classRomminfo.rows.laboratoryName }}</div>
                        <div class="date">{{ getWeekBydate(this.currentdate) }}</div>
                        <div class="date">{{ this.currentdate }}</div>
                    </div>
                    <div class="bar">
                        <div class="content">
                            <div class="bob" v-for="(item, index) in classRomminfo.checkdDateTimeArr" :key="index">
                                <span style="margin-top:10px;display:inline-block">{{ item.longTime }}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- table -->
                <el-form style="padding-left:35px" ref="editFormMember" :rules="editRules" :model="memberForm"
                    label-width="100px">

                    <el-form-item label="团队名称" prop="memberName2" v-if="isShow">
                        <el-input placeholder="请输入团队名称" v-model="memberForm.memberName2"></el-input>
                    </el-form-item>
                    <el-form-item label="研讨目的" prop="memberGoal">
                        <el-radio-group v-model="memberForm.memberOther" @change="discussChange">
                            <el-radio v-for="(v, index) in RemarkList" :key="index" :label="v.remarkId">{{ v.remarkContent
                            }}</el-radio>
                        </el-radio-group>
                        <div style="display:flex;align-items: center;">
                            <!-- <el-radio-group v-model="memberForm.memberOther" style="margin-right:10px;margin-top:5px">
          <el-radio label="other" >{{"其他"}}</el-radio>
        </el-radio-group> -->
                            <el-input style="margin-top:10px" :disabled="isOtherdiscuss" placeholder="请输入其他研讨目的"
                                v-model="memberForm.memberGoal"></el-input>
                        </div>

                    </el-form-item>
                    <el-form-item label="研讨主题" :prop="memberSuject" v-if="isShowMember">
                        <el-input placeholder="请输入研讨主题" v-model="memberForm.memberSuject"></el-input>
                    </el-form-item>
                    <el-form-item label="课程名称" :prop="courseName"
                        v-if="RemarkList.length > 0 && memberForm.memberOther === RemarkList.find(v => v.remarkContent === '教学').remarkId">
                        <el-input placeholder="请输入课程名称" v-model="memberForm.courseName"></el-input>
                    </el-form-item>
                    <el-form-item label="课时" :prop="courseHour"
                        v-if="RemarkList.length > 0 && memberForm.memberOther === RemarkList.find(v => v.remarkContent === '教学').remarkId">
                        <el-input placeholder="请输入课时" v-model="memberForm.courseHour"></el-input>
                    </el-form-item>
                    <el-form-item label="班级" :prop="className"
                        v-if="RemarkList.length > 0 && memberForm.memberOther === RemarkList.find(v => v.remarkContent === '教学').remarkId">
                        <el-input placeholder="请输入班级" v-model="memberForm.className"></el-input>
                    </el-form-item>
                    <el-form-item label="人数" :prop="peopleNumber"
                        v-if="RemarkList.length > 0 && memberForm.memberOther === RemarkList.find(v => v.remarkContent === '教学').remarkId">
                        <el-input placeholder="请输入人数" v-model="memberForm.peopleNumber"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="预约召集人" :prop="memberMan" v-if="isShowMember">
                        <el-input placeholder="请输入预约召集人" v-model="memberForm.memberMan"></el-input>
                    </el-form-item> -->
                </el-form>

            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialg()">取 消</el-button>
                <el-button type="primary" @click="submitsInfo">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 弹框二：会员选择 -->
        <el-dialog v-dialogDrag class="el-dialog2" title="会员选择" :visible.sync="memberDialogVisible" width="50%"
            :before-close="handleClose">
            <!-- 会员选择 -->
            <div class="membercontainer">
                <el-form ref="membercontainerform" :model="formMc" label-width="80px" :inline="true">
                    <el-form-item label="会员名称">
                        <el-input v-model="formMc.memberName" placeholder="请输入会员名称"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码">
                        <el-input v-model="formMc.memberNumber" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-button type="primary" @click="selectData()">查询</el-button>
                    <el-button type="" @click="clearData()">清空</el-button>
                </el-form>
                <!-- button -->

                <!-- table -->
                <el-table style="margin-top:15px" width="100%" ref="multipleTable" :data="memberList" :stripe="isStripe"
                    highlight-current-row @current-change="handleSelectionChange">
                    <el-table-column width="55" align="center">
                        <template slot-scope="scope">
                            <input name="Fruit" type="radio" :value="scope.row.ischecked"
                                @change="changeRedio($event, scope.row)" />
                        </template>
                    </el-table-column>
                    <el-table-column type="index" label="序号" width="100" align="center">
                    </el-table-column>
                    <el-table-column label="会员姓名" align="center">
                        <template slot-scope="scope">{{ scope.row.userName }}</template>
                    </el-table-column>
                    <el-table-column prop="mobilePhone" label="手机号码" align="center">
                    </el-table-column>
                    <el-table-column prop="userType" label="会员身份" align="center">
                        <template slot-scope="scope">{{ scope.row.userType == '1' ? '老师' : '学生' }}</template>
                    </el-table-column>
                </el-table>
                <!-- 分页查询 -->
                <div class="block" style="margin-top:15px">
                    <el-pagination @size-change="handleSizeChange5" @current-change="handleCurrentChange5"
                        :current-page="member_page" :page-sizes="[5, 10, 15]" :page-size="formMc.member_size"
                        layout="total, sizes, prev, pager, next, jumper" :total="member_count">
                    </el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="" @click="memberDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="addData()">添加</el-button>
            </span>

        </el-dialog>
        <!-- 取消预约 -->
        <el-dialog v-dialogDrag class="dialogs" title="取消整间预约" :visible.sync="iscancelapppintment" width="50%"
            :before-close="handleClose">
            <div class="details">
                <div class="info">
                    <div class="content">

                        <div class="time">
                            <div class="title">
                                <div class="text">{{ classRomminfo.rows.laboratoryName }}</div>
                                <div class="date">{{ getWeekBydate(this.currentdate) }}</div>
                                <div class="date">{{ this.currentdate }}</div>
                            </div>
                            <div class="bar">
                                <div class="content">
                                    <div class="bob" v-for="(item, index) in classRomminfo.checkdDateTimeArr" :key="index">
                                        <span style="margin-top:10px;display:inline-block">{{ item.longTime }}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="contenrs" style="height:300px;width:100%"></div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelback()">返回</el-button>
                <el-button type="primary" @click="submitCancelAppointment()">取消预约</el-button>
            </span>
        </el-dialog>

        <el-card class="box-card4" :style="{ width: containerWidth }">
            <div class="bottoms">

                <div class="sm">颜色说明</div>
                <div class="gray">
                    <div class="color"></div>
                    <div class="text">过期说明</div>
                </div>
                <div class="green">
                    <div class="color"></div>
                    <div class="text">可用位置</div>
                </div>
                <div class="red">
                    <div class="color"></div>
                    <div class="text">固定预约</div>
                </div>
                <div class="blue">
                    <div class="color"></div>
                    <div class="text">正常预约</div>
                </div>
                <div class="green_open">
                    <div class="color"></div>
                    <div class="text">开放使用</div>
                </div>
            </div>
        </el-card>
    </d2-container>
</template>
  
<script>
import classroom from './classroom.vue'
import { dateFormat } from '@/utils/filter'
export default {
    components: { classroom },
    data() {
        return {
            searchForm: {
                laboratoryName: '',
                week: 0,
                classroomId: ''
            },
            token: localStorage.getItem("pc_token"),
            weeks: [],
            classTableData: [],
            currentDay: '',
            classroomDialog: false,
            isRouterAlive: true,
            tableShow: false,
            timeList: [],
            // 其他研讨目的
            isOtherdiscuss: true,
            isShow: false,
            isStripe: false,
            ischecked: false,
            isShowMember: true,
            memberSuject: 'memberSuject',
            memberMan: 'memberMan',
            memberList: [], // 会员数据
            checkedmember: {}, // 当前选中的一行会员
            member_count: 0, // 总数
            member_page: 1, // 当前页
            member_size: 5,
            formMc: {
                memberName: '',
                memberNumber: ''

            },
            setColor: {
                gray: 'gray',
                green: 'green',
                orange: 'orange',
                red: 'red',
                // greenOpen: '#18cca7',
                greenOpen: '#67C23A',
                SkyBlue: '#0099ff'
            },
            memberForm: {
                memberName: '',
                memberName2: '',
                memberOther: '',
                memberGoal: '',
                memberSuject: '',
                memberMan: '',
                courseHour: '',
                className: '',
                peopleNumber: '',
                courseName: ''
            },
            memberDialogVisible: false, // 选择会员-弹框隐藏
            dialogVisible: false, // 弹框隐藏
            RemarkList: [], // 研讨目的
            collegeList: [], // 学院信息
            merchantId: '', // 当前学院id
            queryLabortoryArr: [], // 当前实验室
            laboratoryId: '', // 实验室ID
            LabortoryArr: [], // 教室预约信息List
            myrecord: [], // 记录教室laboratoryId
            dateArr: [], // 记录时间段选中的
            columns: [

            ],

            value: '',
            value2: '', //
            currentdate: '', // 当前日期
            todayTime: '',
            containerWidth: '',
            classRomminfo: {
                checkdDateTimeArr: [],
                rows: {},
                columns: {}
            },
            iscancelapppintment: false, // 是否打开取消预约的弹框
            // 时间对象
            statAndendObj: {
                startTime: '',
                endTime: ''
            },
            appointmentTimeType: 0,
            // 取消预约单选
            goods: {
                id: '',
                data: {}
            },
            editRules: {
                // memberName2: [
                //   { required: true, message: '请输入团队名称', trigger: 'blur' }
                // ],
                memberSuject: [
                    { required: true, message: '请输入研讨主题', trigger: 'blur' }
                ],
            },
        }
    },
    created() {
        this.getCourseScheduleByWeek()
    },
    mounted() {
        this.onclientWidth()
        window.onresize = () => {
            const container = document.querySelector('.module-container')
            if (container) {
                this.containerWidth = container.clientWidth + 'px'
            }

        }
    },
    updated() {
        this.onclientWidth()
    },
    methods: {
        onclientWidth() {
            const container = document.querySelector('.module-container')
            if (container) {
                this.containerWidth = container.clientWidth + 'px'
            }
        },
        getWeekBydate(timestr) {
            var time = timestr || null
            var date = new Date(time)
            switch (date.getDay()) {
                case 1:
                    return '周一'
                    break
                case 2:
                    return '周二'
                    break
                case 3:
                    return '周三'
                    break
                case 4:
                    return '周四'
                    break
                case 5:
                    return '周五'
                    break
                case 6:
                    return '周六'
                    break
                case 0:
                    return '周日'
                    break

                default:

                    break
            }
        },
        /**
            * 数字转中文
            * @param {Number} num 需要转换的数字
            * @param {String} identifier 标识符
            * @returns {String} 转换后的中文
            */
        digital2Chinese(num, identifier) {
            const character = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
            return identifier === 'week' && (num === 0 || num === 7) ? '日' : character[num]
        },
        // vue组件重新加载（刷新）
        reload() {
            this.isRouterAlive = false
            this.$nextTick(() => (this.isRouterAlive = true))
        },
        refreshData() {

        },
        // 判断时间是否过期

        isguoqi(date) {
            if (date) {
                const str = date
                return new Date().getTime() > new Date(str).getTime()
            }
        },
        // 判断时间是否开放
        isKaiFang(uten) {
            if (uten.isOpen == 1) {
                return true
            } else {
                return false
            }
        },
        // 单选 会员
        changeRedio(event, row) {
            console.log('event,row:', event, row)
            this.checkedmember = row
        },
        // 关闭弹框
        closeDialg() {
            this.resetForm('memberForm') // 教室预约-重置表单数据
            this.classRomminfo = {
                checkdDateTimeArr: [],
                rows: {},
                columns: {}
            }
            // this.restTimequantum()// 重置时间

            this.dialogVisible = false
        },
        // 选择教室
        classroomAffirm() {
            this.searchForm.classroomId = this.$refs.classroomRef.radio.laboratoryId
            this.searchForm.laboratoryName = this.$refs.classroomRef.radio.laboratoryName
            this.classroomDialog = false
            this.reload()
            this.searchForm.week = 0
            this.getCourseScheduleByWeek()
        },
        preWeek() {
            this.searchForm.week = this.searchForm.week - 1
            this.getCourseScheduleByWeek()
        },
        nextWeek() {
            this.searchForm.week = this.searchForm.week + 1
            this.getCourseScheduleByWeek()
        },
        getCourseScheduleByWeek() {
            const that = this
            this.$api
                .findLaboratoryByIdApi({
                    token: that.token,
                    ...this.searchForm
                })
                .then(res => {
                    const tableData = res.data
                    this.weeks = res.data.weekList
                    this.currentDay = res.data.currentDay
                    this.classTableData = res?.data?.tableData ?? []
                    console.log('tableData==', tableData)
                })
        },
        // 阿拉伯数字转换为简写汉字
        Arabia_To_SimplifiedChinese(Num) {
            for (let i = Num.length - 1; i >= 0; i--) {
                Num = Num.replace(',', '')// 替换Num中的“,”
                Num = Num.replace(' ', '')// 替换Num中的空格
            }
            if (isNaN(Num)) { // 验证输入的字符是否为数字
                // alert("请检查小写金额是否正确");
                return
            }
            // 字符处理完毕后开始转换，采用前后两部分分别转换
            const part = String(Num).split('.')
            let newchar = ''
            // 小数点前进行转化
            for (let i = part[0].length - 1; i >= 0; i--) {
                if (part[0].length > 10) {
                    // alert("位数过大，无法计算");
                    return ''
                }// 若数量超过拾亿单位，提示
                let tmpnewchar = ''
                const perchar = part[0].charAt(i)
                switch (perchar) {
                    case '0': tmpnewchar = '零' + tmpnewchar
                        break
                    case '1': tmpnewchar = '一' + tmpnewchar
                        break
                    case '2': tmpnewchar = '二' + tmpnewchar
                        break
                    case '3': tmpnewchar = '三' + tmpnewchar
                        break
                    case '4': tmpnewchar = '四' + tmpnewchar
                        break
                    case '5': tmpnewchar = '五' + tmpnewchar
                        break
                    case '6': tmpnewchar = '六' + tmpnewchar
                        break
                    case '7': tmpnewchar = '七' + tmpnewchar
                        break
                    case '8': tmpnewchar = '八' + tmpnewchar
                        break
                    case '9': tmpnewchar = '九' + tmpnewchar
                        break
                }
                switch (part[0].length - i - 1) {
                    case 0: tmpnewchar = tmpnewchar
                        break
                    case 1: if (perchar != 0) tmpnewchar = tmpnewchar + '十'
                        break
                    case 2: if (perchar != 0) tmpnewchar = tmpnewchar + '百'
                        break
                    case 3: if (perchar != 0) tmpnewchar = tmpnewchar + '千'
                        break
                    case 4: tmpnewchar = tmpnewchar + '万'; break
                    case 5: if (perchar != 0) tmpnewchar = tmpnewchar + '十'
                        break
                    case 6: if (perchar != 0) tmpnewchar = tmpnewchar + '百'
                        break
                    case 7: if (perchar != 0) tmpnewchar = tmpnewchar + '千'
                        break
                    case 8: tmpnewchar = tmpnewchar + '亿'
                        break
                    case 9: tmpnewchar = tmpnewchar + '十'
                        break
                }
                newchar = tmpnewchar + newchar
            }
            // 替换所有无用汉字，直到没有此类无用的数字为止
            while (newchar.search('零零') != -1 || newchar.search('零亿') != -1 || newchar.search('亿万') != -1 || newchar.search('零万') != -1) {
                newchar = newchar.replace('零亿', '亿')
                newchar = newchar.replace('亿万', '亿')
                newchar = newchar.replace('零万', '万')
                newchar = newchar.replace('零零', '零')
            }
            // 替换以“一十”开头的，为“十”
            if (newchar.indexOf('一十') == 0) {
                newchar = newchar.substr(1)
            }
            // 替换以“零”结尾的，为“”
            if (newchar.lastIndexOf('零') == newchar.length - 1) {
                newchar = newchar.substr(0, newchar.length - 1)
            }
            return newchar
        },
        // 点击单元格
        cellChange(item, date, has, orderId) {
            const uten = {
                longTime: date + ' ' + item.startTime + '~' + item.endTime,
                has: has,
                orderId: orderId || ''
            }
            console.log(uten)
            // 判断当前操作是选中还是取消
            if (!this.handler(this.dateArr, uten)) {
                console.log('选中')
                this.dateArr.push(uten)
                // this.handlerhightlight(this.dateArr, 'orange')
            } else {
                console.log('取消')
                this.handlerspliceArr(this.dateArr, uten)
                // this.handlerhightlight(id, 'green')
                // if (utens.invalid == null && utens.orderDetailList.length !== 0) {
                //     this.handlerhightlight(id, '#0099ff')
                // }
            }

            console.log('dateArr', this.dateArr)

            this.classRomminfo = Object.assign(this.classRomminfo, { checkdDateTimeArr: this.dateArr }, { rows: item }, { columns: uten })
        },
        handler(arr, item) {
            let a = false
            arr.forEach(res => {
                if (res.longTime == item.longTime) {
                    a = true
                }
            })
            return a
        },
        // 删除取消的一项
        handlerspliceArr(arr, uten) {
            arr.forEach(res => {
                if (res.longTime == uten.longTime) {
                    const index = arr.findIndex((item) => item.longTime == uten.longTime)

                    // this.handlerhightlight(res.id, 'green')
                    arr.splice(index, 1)
                }
            })
        },
        // 设置取消颜色高亮
        handlerhightlight(arr, color) {
            if (typeof arr === 'string') {
                document.getElementById(arr).style.background = color
                return false
            }
            arr.forEach(res => {
                if (res.id !== '') {
                    document.getElementById(res.id).style.background = color
                }
            })
        },
        //
        getasyncREsult() {
            this.getfindMerchantByPage()
        },

        // 当前页数size
        handleSizeChange5(val) {
            // console.log('3',val);
            this.member_size = val
            this.getMemberSchoolUserList()
        },
        // 当前页改变
        handleCurrentChange5(val) {
            // console.log('2',val);
            this.member_page = val
            this.getMemberSchoolUserList()
        },
        // 添加选中的会员
        addData() {
            if (Object.keys(this.checkedmember).length == 0) {
                this.$message.warning('请先选择一条会员！')
                return false
            }
            const { userName } = this.checkedmember
            this.memberForm.memberName = userName
            this.memberDialogVisible = false
        },
        // 监听研讨目的
        discussChange() {
            const memberOther = this.memberForm.memberOther || ''
            // 其他原因
            if (memberOther === 'otherId') {
                this.isOtherdiscuss = false
            } else {
                this.isOtherdiscuss = true
                this.memberForm.memberGoal = ''
            }
        },
        handleSelectionChange(row) {
            // console.log('row',row);
            this.checkedmember = row;

        },
        // 打开会员选择
        openSelectMember() {
            this.memberDialogVisible = true
            this.getMemberSchoolUserList()// 查询会员list
        },
        // 关闭弹框
        handleClose(done) {
            // this.$confirm('确认关闭？')
            //   .then(_ => {
            done()
            // })
            // .catch(_ => { })
        },
        // 预约
        yuyue() {
            if (this.dateArr.length == 0) {
                this.$message.warning('请选择教室时间！')
                return false
            }
            const _roomType = this.classRomminfo.rows.roomType || 0
            let _remarkType = 1
            // 朗读亭
            if (_roomType == 1) {
                _remarkType = 3
                this.isShowMember = false
                this.memberSuject = '1111'
                this.memberMan = '1111'
            } else {
                this.isShowMember = true
                this.memberSuject = 'memberSuject'
                this.memberMan = 'memberMan'
            }

            // 获取研讨目的
            this.getqueryBaseRemarkList(_remarkType)
            // // 判断是否改选中包含已选预约
            let anappointmented = false
            this.dateArr && this.dateArr.forEach(k => {
                if (k.has) {
                    anappointmented = true
                }
            })
            if (anappointmented) {
                this.$message.warning('选择已存在预约不可再次预约,请重新选择！')
                //  this.dateArr=[]
                return false
            }

            this.dialogVisible = true
        },
        // 获取教室预约的研讨目的类型
        getqueryBaseRemarkList(remarkType) {
            const params = {
                token: localStorage.getItem("pc_token"),
                route: 'member',
                page: '',
                limit: '',
                remarkContent: '',
                remarkType: remarkType
            }
            this.$api.queryBaseRemarkList2(params).then(res => {
                console.log('研讨目的', res.data)
                const resData = res?.data?.data || []
                resData.push({ remarkId: 'otherId', remarkContent: '其他' })
                this.RemarkList = resData
            })
        },
        // 点击取消预约
        cancelapppintment() {
            if (Object.keys(this.classRomminfo.rows).length == 0) {
                this.$message.warning('请先选择教室！')
                return false
            }

            // 判断是否选择中包含未预约
            const anappointmented = this.dateArr.every(v => {
                return v.has
            })

            if (!anappointmented) {
                this.$message.warning('选择已存在未预约不可取消预约，请重新选择！')
                // this.dateArr=[]
                return false
            }
            this.iscancelapppintment = true
        },
        // 取消预约 --取消按钮
        cancelback() {
            //this.handlerhightlight(this.dateArr, 'red')
            this.dateArr = []
            //  this.getClassroomPositionData()
            this.iscancelapppintment = false
        },
        // 取消预约方法
        async submitCancelAppointment() {
            console.log('DDS', this.classRomminfo)
            const { checkdDateTimeArr } = this.classRomminfo

            this.dateArr = [];
            this.iscancelapppintment = false
            for (let index = 0; index < checkdDateTimeArr.length; index++) {
                const element = checkdDateTimeArr[index];
                const params = {
                    token: this.$util.cookies.get('token'),
                    orderId: element.orderId
                }
                await this.$api.laboratoryCancelOrder(params)
            }
            this.getCourseScheduleByWeek()
        },
        // 提交预约信息
        submitsInfo() {
            // const userId = this.checkedmember.userId || ''
            // if (userId === '') {
            //     this.$message.error('请选择会员！')
            //     return false
            // }
            // 研讨目的
            const discussId = this.memberForm.memberOther || ''
            // 其他研讨目的
            const discussRemark = this.memberForm.memberGoal || ''
            if (discussId === '') {
                this.$message.error('请选择研讨目的！')
                return false
            }
            if (discussId === 'otherId' && discussRemark === '') {
                this.$message.error('请输入其他研讨目的！')
                return false
            }
            const user = JSON.parse(localStorage.getItem("pc_data"));
            // const { userType } = this.checkedmember// 选中的会员

            const memberName = this.memberForm.memberName || ''
            const memberName2 = this.memberForm.memberName2 || ''
            let _memberMan = this.memberForm.memberMan || ''
            let _memberSuject = this.memberForm.memberSuject || ''
            // const { memberSuject, memberMan, memberName2, memberName } = this.memberForm// 表单
            if (!this.isShowMember) {
                _memberMan = memberName
                _memberSuject = memberName
            }

            // console.log('rows',rows,columns);
            const { rows: { laboratoryId, laboratoryName, createTime }, checkdDateTimeArr } = this.classRomminfo

            const map = {};
            checkdDateTimeArr.forEach(item => {
                const date = item.longTime.split(' ');
                const str = date[1].split('~')
                const d = { startTime: str[0], endTime: str[1], orderId: '' };
                if (!map[date[0]]) {
                    map[date[0]] = [d]
                }
                else {
                    map[date[0]].push(d);
                }
            });

            this.$refs.editFormMember.validate((valid) => {
                if (valid) {
                    const dates = Object.keys(map);



                    const promises = [];
                    for (let index = 0; index < dates.length; index++) {
                        const date = dates[index];

                        const timeList = map[date]
                        // console.log('timeList', timeList)

                        const pointStr = [
                            {
                                laboratoryId: this.searchForm.classroomId,
                                laboratoryName: this.searchForm.laboratoryName,
                                appointmentDate: dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                                weekDay: this.getWeekBydate(date),
                                timeList: timeList
                            }
                        ]
                        const data = {
                            token: localStorage.getItem("pc_token"),
                            date: date,
                            memberType: user.userType,
                            userId: user.userId,
                            appId: 'wx6b9de9cb7e1bc6ab',
                            backRemark: '',
                            isClassroomAppointment: 1,
                            teamName: memberName2, // 团队名称
                            topicName: _memberSuject, // 研讨主题
                            leaderName: _memberMan, // 预约召集人
                            remarkIds: discussId === 'otherId' ? '' : discussId, // 研讨目的ID
                            otherRemark: discussRemark, // 其他研讨目的
                            pointStr: JSON.stringify(pointStr),
                            courseName: this.memberForm.courseName,
                            peopleNumber: this.memberForm.peopleNumber,
                            className: this.memberForm.className,
                            courseHour: this.memberForm.courseHour
                        }
                        const p = this.$api.addLaboratoryOrderBatchBack(data);
                        promises.push(p);
                    }
                    Promise.all(promises).then(res => {
                        // 重置表单
                        this.resetForm('memberForm')
                        // this.dateArr.length == 0
                        this.dateArr = [];
                        this.getCourseScheduleByWeek()

                        // this.restTimequantum()
                        this.classRomminfo = {
                            checkdDateTimeArr: [],
                            rows: {},
                            columns: {}
                        }
                        this.dateArr = [];
                        this.memberForm = {
                            memberName: '',
                            memberName2: '',
                            memberOther: '',
                            memberGoal: '',
                            memberSuject: '',
                            memberMan: '',
                            courseHour: '',
                            className: '',
                            peopleNumber: '',
                            courseName: ''
                        };
                        this.dialogVisible = false
                    })
                } else {
                    return false
                }
            })




        },
        // 重置表单数据
        resetForm(formName) {
            console.log('refs', this.$refs[formName])

            setTimeout(() => {
                this.$refs[formName].resetFields()
            }, 200)
        },
        // 获取会员List
        getMemberSchoolUserList() {
            const { memberName, memberNumber } = this.formMc
            const data = {
                token: localStorage.getItem("pc_token"),
                page: this.member_page,
                limit: this.member_size,
                userName: memberName,
                mobilePhone: memberNumber,
                userType: ''
            }
            this.$api.queryMemberSchoolUserList(data).then((res) => {
                if (res.data.code == 0) {
                    console.log('ressa', res.data)
                    this.memberList = res.data.data.map(item => {
                        return {
                            ...item,
                            ischecked: false
                        }
                    })
                    console.log('this.memberList', this.memberList)

                    this.member_count = res.data.count
                }
            })
        },
    }
}
</script>
  
<style lang='scss' scoped>
.module-container {

    .el-radio {
        margin-bottom: 10px;
    }

    .el-dialog {
        width: 100%;

        .content {
            width: 100%;

            .time {
                display: flex;
                height: 50px;

                .title {
                    flex: 1;
                    // padding-left: 20px;
                    font-size: 18px;
                    width: 30%;

                    //  border: 1px red solid;
                    .date {
                        text-align: right;
                        font-size: 14px;
                        margin-top: 10px;
                        margin-right: 15px;
                    }

                    .text {
                        text-align: right;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                .bar {
                    width: 70%;
                    height: 50px;
                    margin-right: 5px;
                    margin-left: 5px;

                    // border: 1px red solid;
                    .content {
                        // overflow-wrap: normal;
                        white-space: nowrap;
                        overflow-x: scroll;

                        //  display: inline-block;
                        .bob {
                            // float: left;
                            display: inline-block;
                            width: 170px;
                            color: white;
                            height: 50px;
                            margin-right: 5px;
                            background: #18cca7;
                            text-align: center;
                            box-sizing: border-box;
                            padding-top: 10px;
                            font-size: 14px;
                            border-radius: 5px;
                            margin-bottom: 3px;
                        }
                    }
                }
            }

            .el-form {
                margin-top: 50px;

                .el-form-item {
                    width: 80%;
                }
            }
        }
    }

    .dialogs {
        width: 100%;

        .details {
            .info {
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: center;

                .infobox {
                    text-align: center;
                    flex: 1;
                    // border: #e6e6e6 solid 1px ;

                    // text-align: right;
                    span {
                        display: block;
                        height: 30px;
                    }

                    span.name {
                        text-align: right;
                    }

                    .week {
                        text-align: right;
                    }

                    .time {
                        text-align: right;
                    }
                }
            }
        }
    }

    .el-form-item {
        margin-bottom: 10px;
    }

    .box-card4 {
        position: fixed;
        bottom: 0px;
        // right: 0;
        z-index: 10;

        .bottoms {
            display: flex;

            width: 100%;

            .sm {
                height: 40px;
                flex: 1;
                display: flex;
                align-items: center;
                //   border:1px red solid
            }

            .gray {
                flex: 2;
                height: 40px;
                display: flex;
                align-items: center;

                .color {
                    width: 60px;
                    height: 30px;
                    background: gray;
                }

                .text {
                    margin-left: 15px;
                }
            }

            .red {
                flex: 2;
                height: 40px;
                display: flex;
                align-items: center;

                .color {
                    width: 60px;
                    height: 30px;
                    background: rgb(224, 74, 74);
                }

                .text {
                    margin-left: 15px;
                }
            }

            .green {
                flex: 2;
                height: 40px;
                display: flex;
                align-items: center;

                .color {
                    width: 60px;
                    height: 30px;
                    background: green;
                }

                .text {
                    margin-left: 15px;
                }
            }

            .blue {
                flex: 2;
                height: 40px;
                display: flex;
                align-items: center;

                .color {
                    width: 60px;
                    height: 30px;
                    background: #0099ff;
                }

                .text {
                    margin-left: 15px;
                }
            }

            .green_open {
                flex: 2;
                height: 40px;
                display: flex;
                align-items: center;

                .color {
                    width: 60px;
                    height: 30px;
                    background: #67C23A;
                }

                .text {
                    margin-left: 15px;
                }
            }
        }
    }
}

.class-table {
    .table-wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .tabel-container {
        margin: 7px;

        table {
            table-layout: fixed;
            width: 100%;

            thead {
                // background-color: #409EFF;
                background-color: #18CCA7;

                th {
                    color: #fff;
                    line-height: 17px;
                    font-weight: normal;
                }
            }

            tbody {
                background-color: #f4f4f5;

                // background-color: #fff;
                td {
                    // color: #677998;
                    color: #333;
                    line-height: 12px;
                }
            }

            th {
                width: 60px;
                padding: 0px 2px;
                font-size: 12px;
                text-align: center;
            }

            td {
                width: 60px;
                padding: 5px 2px;
                font-size: 12px;
                text-align: center;
            }

            tr td:first-child {
                color: #333;

                .period {
                    font-size: 8px;
                }
            }
        }
    }


}
</style>
  